import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Hamburger from "../assets/images/icon/hamburger.inline.svg"
import Close from "../assets/images/icon/close.inline.svg"
import ChevronDown from "../assets/images/icon/chevron-down.inline.svg"
import { useState } from "react"

const Header = ({ siteTitle }) => {
  const subMenuInfoRef = useRef(null)
  const subMenuKostenRef = useRef(null)
  const horizontalKostenSubMenuRef = useRef(null)
  const horizontalInfoSubMenuRef = useRef(null)
  const [heightKosten, setHeightKosten] = useState("0px")
  const [horizontalHeightKosten, setHorizontalHeightKosten] = useState("0px")
  const [horizontalHeightInfo, setHorizontalHeightInfo] = useState("0px")
  const [heightInfo, setHeightInfo] = useState("0px")
  const [rotateKosten, setRotateKosten] = useState("transform duration-700")
  const [rotateInfo, setRotateInfo] = useState("tranform duration-700")
  const [toggleMenu, setToggleMenu] = useState(false)

  const [toggleSubmenu, setToggleSubmenu] = useState({
    name: "",
    isOpen: false,
    height: "0px",
  })

  useEffect(() => {
    if (!toggleMenu) {
      setToggleSubmenu(prevState => ({
        ...prevState,
        name: "",
        isOpen: false,
        height: "0px",
      }))
    }
  }, [toggleMenu])

  useEffect(() => {
    if (toggleSubmenu.name === "kosten" && !toggleSubmenu.isOpen) {
      setHeightKosten("0px")
      setRotateKosten("transform duration-700")
    }

    if (toggleSubmenu.name === "info" && !toggleSubmenu.isOpen) {
      setHeightInfo("0px")
      setRotateInfo("transform duration-700")
    }
  }, [toggleSubmenu])

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu)
  }

  const handleSubMenu = name => {
    setToggleSubmenu(prevState => ({
      ...prevState,
      name,
      isOpen: !prevState.isOpen,
    }))

    if (name === "kosten") {
      setHeightKosten(`${subMenuKostenRef.current.scrollHeight}px`)
      setRotateKosten("transform duration-700 ease rotate-180")
    } else {
      setHeightKosten("0px")
      setRotateKosten("transform duration-700")
    }

    if (name === "info") {
      setHeightInfo(`${subMenuInfoRef.current.scrollHeight}px`)
      setRotateInfo("transform duration-700 ease rotate-180")
    } else {
      setHeightInfo("0px")
      setRotateInfo("transform duration-700")
    }
  }

  return (
    <header className="h-70px bg-primary fixed z-100 w-full left-0 top-0">
      <nav className="px-30px py-15px flex justify-between 2xl:max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md sm:max-w-screen-sm mx-auto bg-primary">
        <Link to="/">
          <StaticImage
            src="../assets/images/eerstmediation-white.png"
            alt="eerstmediation logo"
            className="w-225px"
            placeholder="blurred"
          />
        </Link>
        <div
          className={`fixed bg-primary w-full left-0 px-30px pb-15px mt-70px md:hidden ${
            toggleMenu ? "top-0" : "-top-150%"
          } duration-300 -z-10`}
        >
          <ul className="py-15px">
            <li className="py-3 text-white cursor-pointer">
              <Link to="/mediation-bij-een-echtscheiding">Echtscheiding</Link>
            </li>
            <li className="py-3 text-white cursor-pointer">
              <Link to="/mediation-bij-een-arbeidsconflict">
                Arbeidsconflict
              </Link>
            </li>
            <ul className="py-3 text-white">
              <div className="flex justify-between items-center">
                <span className="cursor-pointer">Kosten</span>
                <button
                  className="h-full"
                  onClick={() => handleSubMenu("kosten")}
                >
                  <div className={`${rotateKosten} flex items-center`}>
                    <ChevronDown />
                  </div>
                </button>
              </div>

              <ul
                className="pl-15px bottom-0 left-0 overflow-y-hidden transition-max-height duration-700 ease-in-out"
                ref={subMenuKostenRef}
                style={{
                  maxHeight: `${heightKosten}`,
                }}
              >
                <li className="py-3 text-white text-sm">
                  <Link to="/kosten-bij-een-echtscheiding">Echtscheiding</Link>
                </li>
                <li className="py-3 text-white text-sm">
                  <Link to="/kosten-bij-een-arbeidsconflict">
                    Arbeidsconflict
                  </Link>
                </li>
              </ul>
            </ul>
            <li className="py-3 text-white">
              <div className="flex justify-between items-center">
                <span className="cursor-pointer">Info</span>
                <button
                  className="h-full"
                  onClick={() => handleSubMenu("info")}
                >
                  <div className={`${rotateInfo} flex items-center`}>
                    <ChevronDown />
                  </div>
                </button>
              </div>

              <ul
                className="pl-15px bottom-0 left-0 overflow-y-hidden transition-max-height duration-700 ease-in-out"
                ref={subMenuInfoRef}
                style={{
                  maxHeight: `${heightInfo}`,
                }}
              >
                <li className="py-3 text-white text-sm">
                  <Link to="/over-het-kantoor">Over het kantoor</Link>
                </li>
                {/* <li className="py-3 text-white text-sm">
                  <Link to="/nieuwsberichten">Nieuwsberichten</Link>
                </li> */}
                <li className="py-3 text-white text-sm">
                  <Link to="/recensies">Recensies</Link>
                </li>
              </ul>
            </li>
          </ul>
          <Link
            to="/contact"
            className="bg-light-green text-white py-3 rounded-3px w-full text-lg leading-4 inline-block text-center hover:underline"
          >
            Contact
          </Link>
        </div>

        <div className="hidden md:flex md:items-center ">
          <ul className="flex gap-x">
            <li>
              <Link
                to="/mediation-bij-een-echtscheiding"
                className="text-white text-lg px-15px py-3 hover:underline cursor-pointer leading-4"
                activeClassName="underline"
              >
                Echtscheiding
              </Link>
            </li>
            <li>
              <Link
                to="/mediation-bij-een-arbeidsconflict"
                className="text-white text-lg px-15px py-3 hover:underline cursor-pointer leading-4"
                activeClassName="underline"
              >
                Arbeidsconflict
              </Link>
            </li>
            <li className="relative">
              <Link
                className="text-white text-lg px-15px py-3 hover:underline cursor-pointer leading-4"
                onMouseOver={() => {
                  setHorizontalHeightKosten(
                    `${horizontalKostenSubMenuRef.current.scrollHeight}px`
                  )
                }}
                onMouseOut={() => {
                  setHorizontalHeightKosten("0px")
                }}
              >
                Kosten
              </Link>
              <ul
                className="absolute top-8 left-0 overflow-y-hidden transition-max-height duration-500 ease-in-out"
                style={{ maxHeight: `${horizontalHeightKosten}` }}
                ref={horizontalKostenSubMenuRef}
                onMouseOver={() => {
                  setHorizontalHeightKosten(
                    `${horizontalKostenSubMenuRef.current.scrollHeight}px`
                  )
                }}
                onMouseOut={() => {
                  setHorizontalHeightKosten("0px")
                }}
              >
                <li className="py-3 px-3 w-180px text-white cursor-pointer border-x border-t last:border-t-0 border-b border-white text-sm bg-primary">
                  <Link
                    to="/kosten-bij-een-echtscheiding"
                    className="text-white hover:underline hover:cursor-pointer"
                  >
                    Echtscheiding
                  </Link>
                </li>
                <li className="py-3 px-3 w-180px text-white cursor-pointer border-x border-t last:border-t-0 border-b border-white text-sm bg-primary">
                  <Link
                    to="/kosten-bij-een-arbeidsconflict"
                    className="text-white hover:underline hover:cursor-pointer"
                  >
                    Arbeidsconflict
                  </Link>
                </li>
              </ul>
            </li>
            <li className="relative">
              <Link
                className="text-white text-lg px-15px py-3 hover:underline cursor-pointer leading-4"
                onMouseOver={() => {
                  setHorizontalHeightInfo(
                    `${horizontalInfoSubMenuRef.current.scrollHeight}px`
                  )
                }}
                onMouseOut={() => {
                  setHorizontalHeightInfo("0px")
                }}
              >
                Info
              </Link>
              <ul
                className="absolute top-8 left-0 overflow-y-hidden transition-max-height duration-500 ease-in-out"
                style={{ maxHeight: `${horizontalHeightInfo}` }}
                ref={horizontalInfoSubMenuRef}
                onMouseOver={() => {
                  setHorizontalHeightInfo(
                    `${horizontalInfoSubMenuRef.current.scrollHeight}px`
                  )
                }}
                onMouseOut={() => {
                  setHorizontalHeightInfo("0px")
                }}
              >
                <li className="py-3 px-3 w-180px text-white cursor-pointer border-x border-t last:border-t-0 border-b border-white text-sm bg-primary">
                  <Link
                    to="/over-het-kantoor"
                    className="text-white hover:underline hover:cursor-pointer"
                  >
                    Over het kantoor
                  </Link>
                </li>
                {/* <li className="py-3 px-3 w-180px text-white cursor-pointer border-x border-t last:border-t-0 border-b border-white text-sm bg-primary">
                  <Link
                    to="/nieuwsberichten"
                    className="text-white hover:underline hover:cursor-pointer"
                  >
                    Nieuwsberichten
                  </Link>
                </li> */}
                <li className="py-3 px-3 w-180px text-white cursor-pointer border-x border-t last:border-t-0 border-b border-white text-sm bg-primary">
                  <Link
                    to="/recensies"
                    className="text-white hover:underline hover:cursor-pointer"
                  >
                    Recensies
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <Link
            to="/contact"
            className="text-white bg-light-green text-lg px-15px py-3  ml-15px rounded-3px leading-4 hover:underline hover:bg-link transition-all ease-in-out duration-300 cursor-pointer"
          >
            Contact
          </Link>
        </div>

        <button
          className="block md:hidden transition-all ease-in-out duration-300"
          onClick={handleToggleMenu}
        >
          {toggleMenu ? <Close /> : <Hamburger />}
        </button>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
