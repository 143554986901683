import React, { useState, useEffect } from "react"

const Cookies = () => {
  const [isShowModal, setIsShowModal] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem("eerstmediation")) {
      setIsShowModal(true)
    }
  }, [])
  const onOk = () => {
    localStorage.setItem("eerstmediation", "cookies")
    setIsShowModal(false)
  }

  const onCancel = () => {
    setIsShowModal(false)
  }

  return (
    <>
      {isShowModal && (
        <div className="bg-white shadow-primary py-3 px-10px flex flex-col md:flex-row justify-center items-center fixed bottom-0 left-0 right-0 z-10">
          <span className="text-15px font-light text-center">
            Deze website maakt gebruik van cookies om de gebruikerservaring te
            verbeteren.
          </span>
          <div>
            <button
              onClick={onOk}
              className="py-9px px-3 m-5px bg-light-green text-white text-13px leading-none cursor-pointer"
            >
              Akkoord
            </button>
            <button
              onClick={onCancel}
              className="py-9px px-3 m-5px bg-black text-white text-13px leading-none cursor-pointer"
            >
              Afwijzen
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Cookies
