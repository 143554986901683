import { Link } from "gatsby"
import React from "react"

import Facebook from "../assets/images/icon/facebook-square-brands.inline.svg"
import Instagram from "../assets/images/icon/instagram-brands.inline.svg"
import LinkedIn from "../assets/images/icon/linkedin-brands.inline.svg"
import Twitter from "../assets/images/icon/twitter-square-brands.inline.svg"

export default function Footer() {
  return (
    <footer className="bg-gray py-50px px-30px">
      <div className="grid grid-cols-1 md:grid-cols-3 text-center gap-y-10 mb-16 max-w-900px mx-auto">
        <div>
          <h4 className="text-white text-xl mb-15px">Mediation</h4>
          <ul>
            <li>
              <Link
                to="/mediation-bij-een-echtscheiding"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Hulp bij echtscheiding
              </Link>
            </li>
            <li>
              <Link
                to="/mediation-bij-een-arbeidsconflict"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Mediation bij een arbeidsconflict
              </Link>
            </li>
            <li>
              <Link
                to="/kosten-bij-een-echtscheiding"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Kosten bij echtscheiding
              </Link>
            </li>
            <li>
              <Link
                to="/kosten-bij-een-arbeidsconflict"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Kosten arbeidsconflict
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h4 className="text-white text-xl mb-15px">Info</h4>
          <ul>
            <li>
              <Link
                to="/over-het-kantoor"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Over het kantoor
              </Link>
            </li>
            <li>
              <Link
                to="/kantoorklachtenregeling"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Kantoorklachtenregeling
              </Link>
            </li>
            <li>
              <Link
                to="/algemene-voorwaarden"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Algemene voorwaarden
              </Link>
            </li>
            <li>
              <Link
                to="/privacystatement"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Privacystatement
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h4 className="text-white text-xl mb-15px">Social Media</h4>
          <ul>
            <li>
              <Link
                to="https://www.facebook.com/eerstmediation/"
                target="_blank"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Facebook
              </Link>
            </li>
            <li>
              <Link
                to="https://twitter.com/riannehillenaar"
                target="_blank"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Twitter
              </Link>
            </li>
            <li>
              <Link
                to="https://www.instagram.com/rianne.hillenaar/"
                target="_blank"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                Instagram
              </Link>
            </li>
            <li>
              <Link
                to="https://www.linkedin.com/in/riannehillenaar/"
                target="_blank"
                className="text-lg text-link underline hover:cursor-pointer"
              >
                LinkedIn
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <p className="text-lg text-white leading-1.625 mb-30px text-center">
        Copyright © 2019 Eerstmediation.nl. Alle rechten voorbehouden.
      </p>
      <div className="flex justify-center gap-x-7">
        <div className="w-30px h-30px">
          <Link
            to="https://www.facebook.com/eerstmediation/"
            className="hover:cursor-pointer"
          >
            <Facebook />
          </Link>
        </div>
        <div className="w-30px h-30px">
          <Link
            to="https://twitter.com/riannehillenaar"
            className="hover:cursor-pointer"
          >
            <Twitter />
          </Link>
        </div>
        <div className="w-30px h-30px">
          <Link
            to="https://www.linkedin.com/in/riannehillenaar"
            className="hover:cursor-pointer"
          >
            <LinkedIn />
          </Link>
        </div>
        <div className="w-30px h-30px">
          <Link
            to="https://www.instagram.com/rianne.hillenaar/"
            className="hover:cursor-pointer"
          >
            <Instagram />
          </Link>
        </div>
      </div>
    </footer>
  )
}
